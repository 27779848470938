import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import ReactPlayer from 'react-player';

import Layout from '../components/layout';
import SEO from '../components/seo';

class IndexPage extends Component {
  state = {
    url: null,
    playing: false,
  };
  videoElement = null;
  // Browser video support detection
  // Based on https://davidwalsh.name/detect-supported-video-formats-javascript
  supportsVideoType = type => {
    // Create an HTML video element, if not already created
    if (this.videoElement === null) {
      // Careful: document is only available after componentDidMount
      this.videoElement = document.createElement('video');
    }

    const formats = {
      ogg: 'video/ogg; codecs="theora"',
      h264: 'video/mp4; codecs="avc1.42E01E"',
      webm: 'video/webm; codecs="vp8, vorbis"',
      vp9: 'video/webm; codecs="vp9"',
      hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
    };

    return this.videoElement.canPlayType(formats[type] || type);
  };
  playVideo = () => {
    // Abort if already playing
    if (this.state.playing) {
      return;
    }

    // Use h264 per default
    let videoUrl = withPrefix('/video/que_restera-t-il.mp4');

    // If no h264 but ogg is supported
    if (!this.supportsVideoType('h264') && this.supportsVideoType('ogg')) {
      videoUrl = withPrefix('/video/que_restera-t-il.ogg');
    }

    this.setState({ url: videoUrl, playing: true });
  };
  render() {
    const { url, playing } = this.state;

    return (
      <Layout>
        <SEO title="Accueil" />
        <Helmet bodyAttributes={{ class: 'home' }} />
        <div className="staticContent" id="staticContent_home">
          <p>
            Leelo Pascal, un artiste complet.
            <br /> Sa vision des sons et des couleurs donne &#224; ses titres
            <br /> une r&#233;f&#233;rence nouvelle &#224; l&#8217;univers Pop
            Rock.
          </p>
          <dl id="videoContainer">
            <dt id="video" onClick={() => this.playVideo()}>
              <ReactPlayer
                width="100%"
                height="100%"
                url={url}
                playing={playing}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      // https://github.com/CookPete/react-player/issues/551
                      controlsList: 'nodownload',
                      onContextMenu: e => e.preventDefault(),
                    },
                  },
                }}
              />
            </dt>
            <dd>
              "Mais que restera-t-il" Musique: Benett Corboz&#160;&#8211; Texte:
              Leelo Pascal/Lamiryan &copy;
            </dd>
          </dl>
          <blockquote>
            <p>Une voix, un feeling, un style pop-rock incomparable...</p>
          </blockquote>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
